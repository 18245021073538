<template>
  <div class="hero-body">
      <div class="container has-text-centered">
          <div class="columns is-vcentered">
              <div class="column is-5">
                  <figure class="image imghas-ratio28">
                      <img src="../assets/title.png" alt="OFFICIAL HIGEDARUMA">
                  </figure>
              </div>
              <div class="column is-6 is-offset-1">
                  <h1 class="title is-2">
                      思いついたら作るよ
                  </h1>
                  <h2 class="subtitle is-4">
                      おじさんが楽しむものづくり
                  </h2>
              </div>
          </div>
      </div>
  </div>
</template>

<style scoped lang="scss">

</style>